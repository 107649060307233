import { Component, OnInit, ViewChildren, QueryList, Output, EventEmitter, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SkillDto, Client } from '../../api-client/api-client';
import { SkillsAdministrationService } from '../skills-administration-service/skills-administration.service';

@Component({
  selector: 'app-knowledge-list-administration',
  templateUrl: './knowledge-list-administration.component.html',
  styleUrls: ['./knowledge-list-administration.component.scss']
})
export class KnowledgeListAdministrationComponent implements OnInit {

  skillsList: SkillDto[];
  newSkill: SkillDto;
  orderedSkills: SkillDto[];
  editorIsOpen: boolean = false;
  confirmationTemplateIsOpen: boolean = false;
  R: number; G: number; B: number;

  @ViewChildren('skillElements') things: QueryList<any>;
  @Output() selectedSkill = new EventEmitter<SkillDto>();
  @Output() fullSkillsList = new EventEmitter<SkillDto[]>();
  @Input() updatedFullSkillsList: boolean = false;

  constructor(private api: Client,
    private skillsAdministrationService: SkillsAdministrationService) {
  }

  ngOnInit() {
    this.api.getAllSkills().subscribe(skills => {
      this.skillsList = skills;
      this.orderedSkills = Object.assign([], skills);
      this.fullSkillsList.emit(this.orderedSkills);
    });
    this.editorIsOpen = false;
  }

  ngOnChanges() {
    this.updatedFullSkillsList = false;
     this.api.getAllSkills().subscribe(skills => {
      this.skillsList = skills;
      this.orderedSkills = Object.assign([], skills);
       this.fullSkillsList.emit(this.orderedSkills);
    });
  }

  openSkillsEditor(skill: SkillDto) {
    this.editorIsOpen = true;
    this.confirmationTemplateIsOpen = false;
    this.skillsAdministrationService.editorIsOpen.next(this.editorIsOpen);
    this.skillsAdministrationService.confirmationTemplateIsOpen.next(this.confirmationTemplateIsOpen);
    this.skillsAdministrationService.listSkillsOrderedByName(this.orderedSkills);
    this.selectedSkill.emit(skill);
  }

  openSkillsEditorNewSkill() {
    this.editorIsOpen = true;
    this.confirmationTemplateIsOpen = false;
    this.skillsAdministrationService.editorIsOpen.next(this.editorIsOpen);
    this.skillsAdministrationService.confirmationTemplateIsOpen.next(this.confirmationTemplateIsOpen);
    this.skillsAdministrationService.listSkillsOrderedByName(this.orderedSkills);
    this.newSkill = new SkillDto();
    this.newSkill.id = 0;
    this.newSkill.name = null;
    this.newSkill.color = null;
    this.newSkill.description = null;
    this.newSkill.skillLevel = 0;
    this.newSkill.skillParent = [];
    this.selectedSkill.emit(this.newSkill);
  }

  getClaseAplicableAEtiqueta(color: string): string {
    let colorAEvaluar = color;
    if (!colorAEvaluar) {
      colorAEvaluar = "gray";
    }
    return `${colorAEvaluar}`;
  }

  getTagColorAndOpacity(skill: SkillDto): string {
    let RGBColor, hexColor;
    hexColor = skill.skillParent[0].color;
    if (!hexColor) {
      hexColor = "gray";
    }
    this.colorHexToRGB(hexColor);
    RGBColor = "rgb(" + this.R + "," + this.G + "," + this.B + ")";
    return RGBColor;
  }

  colorHexToRGB(colorHex: string) {
    this.R = parseInt((cutHex(colorHex)).substring(0, 2), 16);
    this.G = parseInt((cutHex(colorHex)).substring(2, 4), 16);
    this.B = parseInt((cutHex(colorHex)).substring(4, 6), 16);
    function cutHex(colorHex) {
      return (colorHex.charAt(0) == "#") ? colorHex.substring(1, 7) : colorHex;
    }
  }

}
