import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-skills-info',
  templateUrl: './dashboard-skills-info.component.html',
  styleUrls: ['./dashboard-skills-info.component.scss']
})
export class DashboardSkillsInfoComponent  {
   
}
