import { Component, OnInit, HostBinding, QueryList, ViewChildren } from '@angular/core';
import { SkillDto, ISkillDto, Client } from '../../api-client/api-client';
import { debug } from 'util';
import { UpgradableComponent } from 'theme/components/upgradable';
import { Observable, BehaviorSubject } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-knowledge-list',
  templateUrl: './knowledge-list.component.html',
  styleUrls: ['./knowledge-list.component.scss']
})
export class KnowledgeListComponent extends UpgradableComponent implements OnInit {

  skillsList: SkillDto[] = null;
  skill: BehaviorSubject<SkillDto> = new BehaviorSubject<SkillDto>(null);
  @ViewChildren('skillElements') things: QueryList<any>;
  checked: boolean = false;
  R: number; G: number; B: number; 

  constructor(private api: Client) {
    super();
  }

  ngOnInit() {
    this.api.getSkillsByUser().toPromise().then(skills => {
      if (skills != null)
        this.skillsList = skills;
    });
  }

  ngAfterViewInit() {
    this.things.changes.subscribe(t => {
      this.ngForRendred();
    })
  }

  ngForRendred() {
    componentHandler.upgradeAllRegistered();
  }

  @HostBinding('class.projects-table') private readonly projectsTable = true;

  updateSkillLevel(value: number, skill: SkillDto) {
    this.checked = true;
    skill.skillLevel = value;
    this.api.updateSkillLevel(skill).toPromise().then(a => {
      this.api.getSkillsByUser().toPromise().then(skills => {
        if (skills != null)
          this.skillsList = skills
        setTimeout(() => { this.checked = false; }, 1500);
      });
    });
  }
  
  getClaseAplicableAEtiqueta(color: string): string {
    let colorAEvaluar = color;
    if (!colorAEvaluar) {
      colorAEvaluar = "gray";
    }
    return `${colorAEvaluar}`;
  }

  getTagColorAndOpacity(skill: SkillDto): string {
    let RGBColor, hexColor;
    hexColor = skill.skillParent[0].color;
    if (!hexColor) {
      hexColor = "gray";
    }
    this.colorHexToRGB(hexColor);
    RGBColor = "rgb(" + this.R + "," + this.G + "," + this.B + ")";
    return RGBColor;
  }

  colorHexToRGB(colorHex: string) {
    this.R = parseInt((cutHex(colorHex)).substring(0, 2), 16);
    this.G = parseInt((cutHex(colorHex)).substring(2, 4), 16);
    this.B = parseInt((cutHex(colorHex)).substring(4, 6), 16);
    function cutHex(colorHex) {
      return (colorHex.charAt(0) == "#") ? colorHex.substring(1, 7) : colorHex;
    }
  }
}
