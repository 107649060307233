import { Component, OnInit, ViewChildren, ElementRef, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { SkillDto, Client } from '../../api-client/api-client';
import { SkillsAdministrationService } from '../skills-administration-service/skills-administration.service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-skills-editor',
  templateUrl: './skills-editor.component.html',
  styleUrls: ['./skills-editor.component.scss']
})
export class SkillsEditorComponent implements OnInit {

  skill: SkillDto;
  newSkill: SkillDto;
  skillPadre: SkillDto[] = [];
  skillChildrenArray: SkillDto[] = [];
  skillParentsArray: SkillDto[] = [];

  headerText: string;
  editorIsOpen: boolean;
  confirmationTemplateIsOpen: boolean;
  skillHasChildren: boolean;
  skillAlreadyExists: boolean;
  skillNameFieldIsEmpty: boolean;

  @Input() selectedSkill: SkillDto;
  @Input() fullSkillsList: SkillDto[];
  @Output() updatedFullSkillsList = new EventEmitter<boolean>(true);

  constructor(private api: Client,
    private skillsAdministrationService: SkillsAdministrationService) {
  }

  ngOnInit() {
    this.skillsAdministrationService.editorIsOpen.subscribe(value => this.editorIsOpen = value);
    this.skillsAdministrationService.confirmationTemplateIsOpen.subscribe(value => this.confirmationTemplateIsOpen = value);
    this.setHeader();
  }

  ngOnChanges() {
    this.skillParentsArray = [];
    this.skill = Object.assign({}, this.selectedSkill);
    this.updatedFullSkillsList.emit(false);
    if (this.selectedSkill != null) {
      this.skillAlreadyExists = false;
      this.skillParentsArray = Object.assign([], this.selectedSkill.skillParent);
      this.api.getChildrenSkills(this.selectedSkill.id).subscribe(cs => this.skillChildrenArray = cs);
      if (this.skill.id != 0) {
        this.skillAlreadyExists = true;
      }
      this.testFieldIsNotEmpty(this.selectedSkill.name);
    }
  }

  closeSkillsEditor() {
    this.editorIsOpen = false;
    this.confirmationTemplateIsOpen = false;
  }

  setHeader() {
    this.headerText = "Propiedades Skill";
  }

  getSelectedOptionOnChange(selectedSkillId: number) {
    this.api.getFullParentSkillsList(selectedSkillId).subscribe(ps => {
    this.skillParentsArray = ps;
      this.fullSkillsList.forEach(s => {
        if (s.id == selectedSkillId) {
          this.skillParentsArray.push(s);
        }
      });
    });  
  }

  comprobationBeforeDelete(selectedSkillId: number) {
    this.skillHasChildren = false;
    if (this.skillChildrenArray.length > 0) {
      this.skillHasChildren = true;
    }
    if (this.skillHasChildren) {
      this.confirmationTemplateIsOpen = true;
    } else {
      this.deleteSkill(selectedSkillId);
    }   
  }

  declineDelete() {
    this.confirmationTemplateIsOpen = false;
  }

  updateInsertSkill() {
    this.skill.skillParent = [];
    this.fullSkillsList.forEach(s => {
      if (s.id == this.skill.skillParentId) {
        this.skill.skillParent.push(s);
      }
    });
    if (this.skill.description == '') {
      this.skill.description = null;
    }
    if (this.skill.color == 'undefined') {
      this.skill.color = null;
    }  
    this.api.insertUpdateSkill(this.skill).subscribe(result => {
      this.editorIsOpen = false;
      this.updatedFullSkillsList.emit(true);
    });
  }

  deleteSkill(skillId: number) {
    this.confirmationTemplateIsOpen = false;
    this.api.deleteSkill(skillId).subscribe(result => {
      this.editorIsOpen = false;
      this.updatedFullSkillsList.emit(true);
    });
  }

  testFieldIsNotEmpty(str: string) {
    this.skillNameFieldIsEmpty = false;
    if (str == null) {
      this.skillNameFieldIsEmpty = true;
    }     
    if (str == '') {
      this.skillNameFieldIsEmpty = true;
    }
  }

}
