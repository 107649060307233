import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ThemeModule } from 'theme';

import { CotoneasterCardComponent } from '../dashboard/cotoneaster-card';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { LineChartComponent } from '../dashboard/line-chart';
import { PieChartComponent } from '../dashboard/pie-chart';
import { RobotCardComponent } from '../dashboard/robot-card';
import { TableCardComponent } from '../dashboard/table-card';
import { TodoListComponent } from '../dashboard/todo-list';
import { TrendingComponent } from '../dashboard/trending';
import { WeatherComponent } from '../dashboard/weather';
import { DashboardSkillsInfoComponent } from './dashboard-skills-info.component';
import { KnowledgeListComponent } from './knowledge-list/knowledge-list.component';

@NgModule({
   imports: [
    CommonModule,
    ThemeModule,
    FormsModule,

  ],
  declarations: [
    DashboardSkillsInfoComponent,
    KnowledgeListComponent
  ],
  providers: [],
  exports: [
    KnowledgeListComponent,
  ]
})
export class DashboardSkillsInfoModule { }
