import { NgModule } from '@angular/core';
import { API_BASE_URL, Client } from './api-client';



@NgModule({
  imports: [
  ],
  declarations: [],
  providers: [
    Client
  ]
})
export class ApiClientModule {
  static withProviders(baseUrl) {
    return {
      ngModule: ApiClientModule,
      providers: [
        { provide: API_BASE_URL, useValue: baseUrl }
      ]
    }
  }
}
