import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SkillDto, Client } from '../../api-client/api-client';

@Injectable({
  providedIn: 'root'
})
export class SkillsAdministrationService {

  editorIsOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  confirmationTemplateIsOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  constructor() { }

  listSkillsOrderedByName(skills: SkillDto[]) {
    return skills.sort((a, b) => {
      var skillNameA = a.name.toUpperCase();
      var skillNameB = b.name.toUpperCase();
      return (skillNameA < skillNameB) ? -1 : (skillNameA > skillNameB) ? 1 : 0;
    });
  }

}
