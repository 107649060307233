import { Component } from '@angular/core';
import { AdalService } from 'adal-angular4';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent {

  private adalConfig = {
    tenant: window['__env']['tenant'],
    clientId: window['__env']['clientId'],
    redirectUri: window['__env']['redirectUri'],
    postLogoutRedirectUri: window['__env']['postLogoutRedirectUri'],
    endpoints: window['__env']['endpoints'],
    extraQueryParameter: 'nux=1&scope=openid profile offline_access User.Read Directory.Read.All'
  };

  constructor(private adal: AdalService) {

    this.adal.init(this.adalConfig);
  }

}
