import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeModule } from '../../../theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardSkillsAdministrationComponent } from './dashboard-skills-administration.component';
import { KnowledgeListAdministrationComponent } from './knowledge-list-administration/knowledge-list-administration.component';
import { SkillsEditorComponent } from './skills-editor/skills-editor.component';
import { MaterialAngularSelectModule } from 'material-angular-select';
import { ColorPickerModule } from 'ngx-color-picker';

@NgModule({
  imports: [
    CommonModule,
    ThemeModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialAngularSelectModule,
    ColorPickerModule
  ],
  declarations: [
    DashboardSkillsAdministrationComponent,
    KnowledgeListAdministrationComponent,
    KnowledgeListAdministrationComponent,
    SkillsEditorComponent
  ],
  providers: [],
  exports: [
    KnowledgeListAdministrationComponent,
    SkillsEditorComponent
  ]
})
export class DashboardSkillsAdministrationModule { }
