import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger,  state,  style,  animate,  transition } from '@angular/animations';
import { SkillDto } from '../api-client/api-client';

@Component({
  selector: 'app-dashboard-skills-administration',
  templateUrl: './dashboard-skills-administration.component.html',
  styleUrls: ['./dashboard-skills-administration.component.scss'],
  animations: []
})
export class DashboardSkillsAdministrationComponent {
  //Outputs from list to editor
  editedSkill: SkillDto;
  skillListForSelect: SkillDto[];

  selectSkillFromList(skill: SkillDto) {
    this.editedSkill = skill;
  }

  fullSkillsListForSelect(skills: SkillDto[]) {
    this.skillListForSelect = skills;
  }

  //Outputs from editor to list
  updatedSkillsListForSelect: boolean;

  updateFullSkillsList(value: boolean) {
    this.updatedSkillsListForSelect = value;
  }
}
