import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutingModule } from 'app/app-routing.module';

import { AuthService } from '@services/*';
import { AdalService } from 'adal-angular4';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL, Client } from '../../pages/api-client/api-client';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-common-layout',
  templateUrl: './common-layout.component.html',
})
export class CommonLayoutComponent implements OnInit {

  user;
  isAdmin = false;
  volverASkills: boolean = false;
  isOnAdminComponent: boolean;
  isOnSkillAdmin: boolean = true;
  userPhoto = 'iVBORw0KGgoAAAANSUhEUgAAASwAAAEsCAMAAABOo35HAAAAM1BMVEX////i4uLZ2dnLy8vGxsa9vb2zs7P6+vrn5+fU1NTBwcH19fXe3t64uLjQ0NDx8fHs7OyvA5BIAAAEMUlEQVR4XuzAIQEAAAgDsDc4/dPSAI3Y8hEAAAAAAAAAAAAAAAAAAAAAAAAAAECnObHs3VnS2yAQhdFGQDMJS/tfbV5SlcpDKo0t/NvoO0u4JaPLJJ9u8yHqHzH4zZ3yN6Rcqv5LLTn9DgBtD/o/YW8CyV5tfJabc1HtoiMqA+I6go4Lh9zRps/Z5HZa1WfVJvdydn1eP+VOnL7GkdUAR1YvpEVWpJX1GlnWd3S9Rj9kdanqVWqSxRW9TpG1nXqlU1aWol4pJibPdpusq+nVmizroVd78GANaDxYPFqp6wRJlrTrDLssKeoMUVZ06BwHs0K7wq/QLvIrHHDcelrIBDHoLEGWo/Ow6jfgvPGQxaDldR5/45ZF09KZGN8HnOzZ22X6+/QOT1jrT3aY8BAWYc3XdaZOJx1AWIRFWIRFWIRFWIRFWIRFWMwNCYuwOHu7zClclpUJaz72DQmL7XsW4bssJlCz7B7ULE7+cab0my5mckGT5sClgTmKzlJufY+Vu6wnL8MBjO9czpzDcxnFbmOyY3cyvtslteOTIVVnqHy3Z8piFh3eyZIOvuowoLNZYeeppHY7Sw52B0PWgM4smk9vTpG5Sm6XmBgO8BQHO/djcx2+69qTrOzBu9DuoJEOCOzb2zlK1oDKgrLdybbOgMCIZdc47zdgYyPaLkX+n4g/g5wj0EftMn10QGTp3W5nA8wusZo8wLOabOfoDXaJX+GAytqMXeGyjt3JQtYA7p8MqAxZdp6WZbex7GfnqKR2Jy9Du0R/H0BzGBBYUbbzNAe7jeZg52gOdifNwa5xxmEAzYGw5gjULMIiLMJigF9S41oFc8MZWtfn9EZxoDywfc+Ro/c6uGhodnR9Tb9PWq7rq7rjqPLfqFvJ6zV8ktXlrlfpWZbWvF7JN1lW2rpeq2+Jl6Bd35d8qqLOEVd7ulrpOk8vTZaRvc7ms6ygbVHfIW5Nvlvaq75P3b83r+a8vpv/yrzaXvVn1P2Qb5JL1J8US/6WR8rrJ/Cf/oAl94j6OeLDJflIKZeqn6eWnL4vKAKzB0Vgv9q7oxyHQRgIwyQlpqQYfP/T7uO+jqtuV23+7wgjoYEIYikoAtODIrDop32us0d5k7VX+3x1X2847rl9C79tj/9ee6zIdp9u38lfu89v282+2+1VealJkVdMt6vwGeV5bRx2Lcdo5Slr2hXNVdKi2lXV+KOoiGtVu7q6iqR1g1lv2m0zaHfl2jSIFzHXab9wLH0Jwu+JNyLYEllhSwzEQeiPJeEPfSwVzsSDGgx9pCW86U/hseuzw+GJQTi462/h0fV9A87EP3SQOOkg9CM0Nn2XhV2fN4iqT9DDkZhFhaaXIUL/PIOhH3bQ9TJE1b/PwBNliKaXIUIvQwy9DNH1MkTVyxCeKEM0vQwRehli6GWIrpchql6G8EQZopQfOGGzTauBZJgAAAAASUVORK5CYII=';

  constructor(
              private userService: UserService,
              private adal: AdalService,
              private api: Client         
  ) {
    this.isAdmin = this.userService.getIsAdmin();
  }

  public ngOnInit() {
    this.user = {
      username: this.adal.userInfo.userName,
      email: this.adal.userInfo.userName,
      token: this.adal.userInfo.token,
    };

   

    this.api.getPhoto().subscribe(data => {
      this.userPhoto = data;
    });

  }


  public logout() {
    this.userService.Logout();
  }

  volverASkillsChanger() {
    if (this.volverASkills) {
      this.volverASkills = false;
      this.isOnSkillAdmin = true;
    } else {
      this.volverASkills = true;
      this.isOnSkillAdmin = false;
    }
  }

}


