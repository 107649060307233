import { Injectable } from '@angular/core';
import { Client, UserDto } from '../pages/api-client/api-client';
import { BehaviorSubject } from 'rxjs';
import { AdalService } from 'adal-angular4';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private user: UserDto;
  //private isAdmin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  //private authenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private api: Client, private adal: AdalService) { }

  public getUser(): UserDto {
    if (!this.user) {
      this.user = JSON.parse(sessionStorage.getItem('user'));
    }
      return this.user;
  }

  public setUser(user: UserDto) {
    sessionStorage.setItem('user', JSON.stringify(user));
    this.user = user;
  }


  getIsAdmin(): boolean {
    if (this.getUser()) {
      return this.getUser().isAdmin;
    }
    return false;
  }
 
  Logout() {
    sessionStorage.removeItem('user');
    this.adal.logOut();
  }
}
