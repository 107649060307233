import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdalService } from 'adal-angular4';
import { Client } from '../../pages/api-client/api-client';
import { UserService } from '../user.service';
import { BlankLayoutCardComponent } from '../../components/blank-layout-card';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss', '../../components/blank-layout-card/blank-layout-card.component.scss'],
})
export class AuthCallbackComponent extends BlankLayoutCardComponent implements OnInit {

  constructor(private router: Router,
            private adal: AdalService,
            private api: Client,
    private userService: UserService) {
    super();
  }

  ngOnInit() {
    this.adal.handleWindowCallback();
      this.api.getUser().subscribe(user => {
        this.userService.setUser(user);
        this.router.navigate(['/']);
      });
  }

}
