import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AuthService, FakeBackendInterceptor, AuthCallbackComponent, AuthGuard} from '@services/*';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChartsModule } from './pages/charts';
import { ComponentsModule } from './pages/components';
import { DashboardModule } from './pages/dashboard';
import { Dashboard2Module } from './pages/dashboard2';
import { FormsModule } from './pages/forms';
import { AdalService, AdalInterceptor } from 'adal-angular4';
import { Client, API_BASE_URL } from './pages/api-client/api-client';
import { environment } from 'environments/environment';
import { ApiClientModule } from './pages/api-client/api-client.module';
import { AuthIsAdmin } from './services/auth/auth.isAdmin';

@NgModule({
  declarations: [
    AppComponent,
    AuthCallbackComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ComponentsModule,
    DashboardModule,
    Dashboard2Module,
    FormsModule,
    ChartsModule,
    HttpClientModule,
    ApiClientModule,
    BrowserAnimationsModule
  ],
  providers: [
 
    AuthService,
    AdalService,
    { provide: HTTP_INTERCEPTORS, useClass: AdalInterceptor, multi: true },
    { provide: API_BASE_URL, useFactory: getBaseUrl },
    AuthGuard,
    Client,
    AuthIsAdmin
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

export function getBaseUrl(): string {
  return window['__env']['ApiBaseURL'];
}
