import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AdalService } from 'adal-angular4';
import { Client } from '../../pages/api-client/api-client';
import { UserService } from '../user.service';

@Injectable()
export class AuthIsAdmin implements CanActivate {

  constructor(private userService: UserService, private adal: AdalService, private root: Router) { }

  canActivate(): boolean {
    if (this.userService.getIsAdmin()) {
      return true;
    }
    else {
      this.root.navigate(['']);
      return false;
    }
  }

}
